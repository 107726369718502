











































































// TODO: Trans
import Vue from "vue";
import { Product } from "@/core/models";
import { Action, Getter } from "vuex-class";
import { Component, Prop } from "vue-property-decorator";

@Component
export default class ProductItem extends Vue {
  @Prop({ default: () => {} }) item!: Product;
  @Getter("products/loading") loading!: boolean;
  @Action("products/update") updateProduct!: Function;
  @Action("displaySnackbar") displaySnackbar!: Function;

  dialog = false;
  employees = 0;

  async update() {
    const metadata = { employees: this.employees };
    const data = {
      id: this.item.id,
      name: this.item.name,
      description: this.item.description,
      active: this.item.active,
      metadata: metadata,
    };
    this.updateProduct(data);
  }

  parseMeta() {
    const {
      metadata: { employees },
    } = this.item;
    if (employees) this.employees = employees;
    else this.employees = 0;
  }
  created() {
    this.parseMeta();
  }
}
